import * as loggedAsTypes from '@/constants/loggedAsTypes'
import { verifyRouteRolesUser } from '@/router/middlewares'
const AdditionalDocuments = () => import('../pages/AdditionalDocuments')
const AwaitingSpecialist = () => import('../pages/AwaitingSpecialist.vue')
const ConsultantDirection = () => import('../pages/ConsultantDirection.vue')
const DiagnosisComplete = () => import('../pages/DiagnosisComplete.vue')
const GenerateDiagnosisComplete = () =>
  import('../pages/GenerateDiagnosisComplete.vue')
const SolutionsExecution = () => import('../pages/SolutionsExecution.vue')
const StudyPresentation = () => import('../pages/StudyPresentation')
const StudyPresentationNew = () => import('../pages/StudyPresentationNew')

export default [
  {
    path: '/diagnosis/complete',
    name: 'DiagnosisComplete',
    component: DiagnosisComplete,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER]),
  },
  {
    path: '/diagnosis/complete/:customerId(\\d+)',
    name: 'GenerateDiagnosisComplete',
    component: GenerateDiagnosisComplete,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER]),
  },
  {
    path: '/diagnosis/complete/:customerId(\\d+)/:continueDiagnosisId(\\d+)',
    name: 'GenerateDiagnosisCompleteContinue',
    component: GenerateDiagnosisComplete,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER]),
  },
  {
    path: '/diagnosis/complete/consultant-direction/:diagnosisId(\\d+)',
    name: 'ConsultantDirection',
    component: ConsultantDirection,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER]),
  },
  {
    path: '/diagnosis/complete/consultant-direction/:diagnosisId(\\d+)/study-presentation',
    name: 'StudyPresentation',
    component: StudyPresentation,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER]),
  },
  {
    path: '/diagnosis/complete/consultant-direction/:diagnosisId(\\d+)/study-presentation-new',
    name: 'StudyPresentationNew',
    component: StudyPresentationNew,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER]),
  },
  {
    path: '/diagnosis/complete/consultant-direction/:diagnosisId(\\d+)/additional-documents',
    name: 'AdditionalDocuments',
    component: AdditionalDocuments,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER]),
  },
  {
    path: '/diagnosis/complete/consultant-direction/:diagnosisId(\\d+)/awaiting-specialist',
    name: 'AwaitingSpecialist',
    component: AwaitingSpecialist,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER]),
  },
  {
    path: '/diagnosis/complete/consultant-direction/:diagnosisId(\\d+)/solutions-execution',
    name: 'SolutionsExecution',
    component: SolutionsExecution,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER]),
  },
]
